/** @format */
import { useMutation } from "@apollo/client";
import { genericPhoneSchema } from "@roadflex/constants";
import { UPDATE_EMAIL_OR_PHONE_WITH_VERIFICATION_CODE } from "@roadflex/graphql";
import { PhoneVerificationValues } from "@roadflex/types";
import { useFormik } from "formik";

interface usePhoneVerificationProps {
  onSubmit: (res: any, error: Error | null) => void;
  initialValues: PhoneVerificationValues;
}

export const usePhoneVerification = ({
  onSubmit,
  initialValues,
}: usePhoneVerificationProps) => {
  const [phoneVerificationFn] = useMutation<{
    updateEmailOrPhoneWithVerificationCode: {
      code: string;
      message: string;
    };
  }>(UPDATE_EMAIL_OR_PHONE_WITH_VERIFICATION_CODE, {
    fetchPolicy: "no-cache",
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    validationSchema: genericPhoneSchema,
    onSubmit: async (value) => {
      try {
        const res = await phoneVerificationFn({
          variables: {
            data: {
              phoneNumber: value.phoneNumber.trim().match(/\d+/g)?.join(""),
              otp: value.otp,
            },
          },
        });

        onSubmit(res, null);
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};
